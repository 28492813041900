import React from 'react'
import ReactAudioPlayer from 'react-audio-player'

import { Row } from 'react-bootstrap'

const Approve = ({ handleClick, changeStep, audioObj, audioUrl }) => (
  <>
    <Row>
      <p className='title'>Approve Recording</p>
      <p className='content ml-auto'>
        Step 2 of 3
      </p>
    </Row>
    <Row>
      <p className='content'>
        Use this recording or re-rercord:
      </p>
    </Row>
    <Row>
      <ReactAudioPlayer
        src={audioUrl}
        controls
      />
    </Row>
    <Row>
      <button className='rerecordButton' onClick={() => changeStep('record')}>Re-record</button>
      <button className='useRecordingButton' onClick={handleClick}>Use this recording</button>
    </Row>
  </>
)

export default Approve
