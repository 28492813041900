import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import * as serviceWorker from './Recorder/serviceWorker';

import Recorder from './Recorder/App';
import VisionBoardMaker from './VisionBoardMaker/App';
import VisionBoardConfig from './VisionBoardConfig/App';

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route path='/'>
                <Route index element={<Recorder />} />
                <Route path='VisionBoardMaker' element={<VisionBoardMaker />} />
                <Route path='VisionBoardConfig' element={<VisionBoardConfig />} />
            </Route>
        </Routes>
    </BrowserRouter>
)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
