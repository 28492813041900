import React from 'react'

import { Row } from 'react-bootstrap'

const Intro = ({ handleClick }) => (
  <>
    <Row>
      <p className='title'>Leave us a message!</p>
    </Row>
    <Row>
      <p className='content'>
        By submitting content through this app, you are agreeing to our terms and conditions
        available at www.website.com. You're giving The Amy Beecher Show permission to use your submission.
      </p>
    </Row>
    <Row>
      <button className='startButton' onClick={handleClick}>Get Started</button>
    </Row>
  </>
)

export default Intro
