import React, { useState } from 'react'
import axios from 'axios'

import { Form, Row, Col } from 'react-bootstrap'

const Submit = ({ changeStep, audioUrl, audioBlob }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)

  const shouldDisableSubmit = firstName.length === 0 || lastName.length === 0 || email.length === 0 || !hasAgreedToTerms

  const handleSubmission = () => {
    const data = new FormData()
    data.append('firstName', firstName)
    data.append('lastName', lastName)
    data.append('email', email)
    data.append('blob', audioBlob)
    axios({
      method: 'post',
      url: 'api/upload/index.php',
      data: data
    }).then((res) => {
      console.log(res)
    }, (error) => {
      console.log(error)
    })
  }

  return (
    <>
      <Row>
        <p className='title'>Enter your info</p>
        <p className='content ml-auto'>
          Step 3 of 3
        </p>
      </Row>
      <Row>
        <Col className='col-left'>
          <input placeholder='First name' onChange={(e) => setFirstName(e.target.value)}/>
        </Col>
        <Col className='col-right'>
          <input placeholder='Last name' onChange={(e) => setLastName(e.target.value)}/>
        </Col>
      </Row>
      <Row>
        <Col className='col-left-top'>
          <input placeholder='Email' onChange={(e) => setEmail(e.target.value)}/>
        </Col>
        <Col className='col-right' />
      </Row>
      <Row>
        <Col className='col-left-top'>
          <Form.Check
            type="checkbox"
            label={"I acknowledge and agree to The Amy Beecher Show's Terms of Use and to the submission terms below. *"}
            onClick={() => setHasAgreedToTerms(!hasAgreedToTerms)}
          />
        </Col>
      </Row>
      <Row>
        <button className='rerecordButton' onClick={() => changeStep('intro')}>Cancel</button>
        <button
          className='useRecordingButton'
          disabled={shouldDisableSubmit}
          onClick={handleSubmission}
        >
          Submit your recording
        </button>
      </Row>
      <Row>
        <p className='terms'>
          * By checking this box and submitting your content through this form (the "Content"), you
          represent, warrant, and agree that (i) information included in the Content is true and accurate
          to the best of your knowledge, (ii) the Amy Beecher Show may use, rework, edit, and share this Content
          in any manner or medium for the purposes called out in the attached submission page, including in
          creation and promotion of programming and promotion of that programming and The Amy Beecher Show, and (iii)
          you are over the age of 18 and have the right to make these representations and to grant these rights
          to the Amy Beecher Show.
        </p>
      </Row>
    </>
  )
}

export default Submit
