import React, { useState, useEffect } from 'react';
import './App.css';

import { Container, Row } from 'react-bootstrap'
import monotoneImg from './monotone-80s-16.jpg'

import Intro from './Intro'
import Record from './Record'
import Approve from './Approve'
import Submit from './Submit'

const App = () => {
  const steps = {
    intro: { Component: Intro, destination: 'record' },
    record: { Component: Record, destination: 'approve' },
    approve: { Component: Approve, destination: 'submit' },
    submit: { Component: Submit },
  }

  const [step, changeStep] = useState('intro')

  const [startRecording, setStartRecording] = useState()
  const [stopRecording, setStopRecording] = useState()
  const [audioObj, setAudioObj] = useState()
  const [audioUrl, setAudioUrl] = useState()
  const [audioBlob, setAudioBlob] = useState()

  const { Component, destination } = steps[step]

  const recordAudio = () => {
    return new Promise(resolve => {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const mediaRecorder = new MediaRecorder(stream);
          let audioChunks = [];

          mediaRecorder.addEventListener("dataavailable", event => {
            audioChunks.push(event.data);
          });

          const start = () => {
            audioChunks = []
            mediaRecorder.start();
          };

          const stop = () => {
            return new Promise(resolve => {
              mediaRecorder.addEventListener("stop", () => {
                const audioBlob = new Blob(audioChunks, {type: 'audio/mpeg-3'});
                const audioUrl = URL.createObjectURL(audioBlob);
                const audioObj = new Audio(audioUrl);

                resolve({audioBlob, audioUrl, audioObj});
              });

              mediaRecorder.stop();
            });
          };

          resolve({start, stop});
        });
    });
  };

  useEffect(() => {
    recordAudio().then((data) => {
      setStartRecording(() => data.start)
      setStopRecording(() => data.stop)
    })
  }, [])

  return (
    <div className="App">
      <div className="header">
        <div className="float-left">
          The Amy Beecher Show :)
        </div>
        <div className="float-right">
          <a href="/">Home</a>
        </div>
      </div>
      <Container>
        <Component
          handleClick={() => changeStep(destination)}
          changeStep={changeStep}
          startRecording={startRecording}
          stopRecording={stopRecording}
          audioObj={audioObj}
          setAudioObj={setAudioObj}
          audioUrl={audioUrl}
          setAudioUrl={setAudioUrl}
          audioBlob={audioBlob}
          setAudioBlob={setAudioBlob}
        />
        <Row>
          <img className='monotoneImg' src={monotoneImg} alt='monotone' />
        </Row>
      </Container>
    </div>
  )
}

export default App;
