import React, { useEffect, useState } from 'react';

import ImageDisplayer from './ImageDisplayer';

import { 
    baseLayerMinKey,
    baseLayerMaxKey,
    middleLayerAMinKey,
    middleLayerAMaxKey,
    middleLayerBMinKey,
    middleLayerBMaxKey,
    middleLayerCMinKey,
    middleLayerCMaxKey,
    middleLayerDMinKey,
    middleLayerDMaxKey,
    middleLayerEMinKey,
    middleLayerEMaxKey,
    middleLayerFMinKey,
    middleLayerFMaxKey,
    baseLayerMinDefault,
    baseLayerMaxDefault,
    middleLayerAMinDefault,
    middleLayerAMaxDefault,
    middleLayerBMinDefault,
    middleLayerBMaxDefault,
    middleLayerCMinDefault,
    middleLayerCMaxDefault,
    middleLayerDMinDefault,
    middleLayerDMaxDefault,
    middleLayerEMinDefault,
    middleLayerEMaxDefault,
    middleLayerFMinDefault,
    middleLayerFMaxDefault,
} from '../constants';

import './App.css';

const importAll = r => {
    return r.keys().map((item) => r(item));
}

const baseLayer = importAll(require.context('./images/BaseLayer', false, /\.(png|jpe?g|svg|PNG)$/));
const layerA = importAll(require.context('./images/MiddleLayerA', false, /\.(png|jpe?g|svg|PNG)$/));
const layerB = importAll(require.context('./images/MiddleLayerB', false, /\.(png|jpe?g|svg|PNG)$/));
const layerC = importAll(require.context('./images/MiddleLayerC', false, /\.(png|jpe?g|svg|PNG)$/));
const layerD = importAll(require.context('./images/MiddleLayerD', false, /\.(png|jpe?g|svg|PNG)$/));
const layerE = importAll(require.context('./images/MiddleLayerE', false, /\.(png|jpe?g|svg|PNG)$/));
const layerF = importAll(require.context('./images/MiddleLayerF', false, /\.(png|jpe?g|svg|PNG)$/));

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

const getLayerImages = (layer, min, max) => {
    const minInt = parseInt(min);
    const maxInt = parseInt(max);
    const computedMax = Math.min(layer.length, maxInt);
    if (minInt > computedMax) {
        return [];
    }

    const amountOfImages = Math.floor(Math.random() * (computedMax - minInt + 1) + minInt);

    if (amountOfImages === 0) {
        return [];
    }

    shuffleArray(layer);

    return layer.slice(amountOfImages - 1);

}

const createImageArray = () => {
    const baseImages = getLayerImages(baseLayer, localStorage.getItem(baseLayerMinKey) ?? baseLayerMinDefault, localStorage.getItem(baseLayerMaxKey) ?? baseLayerMaxDefault);
    const aImages = getLayerImages(layerA, localStorage.getItem(middleLayerAMinKey) ?? middleLayerAMinDefault, localStorage.getItem(middleLayerAMaxKey) ?? middleLayerAMaxDefault);
    const bImages = getLayerImages(layerB, localStorage.getItem(middleLayerBMinKey) ?? middleLayerBMinDefault, localStorage.getItem(middleLayerBMaxKey) ?? middleLayerBMaxDefault);
    const cImages = getLayerImages(layerC, localStorage.getItem(middleLayerCMinKey) ?? middleLayerCMinDefault, localStorage.getItem(middleLayerCMaxKey) ?? middleLayerCMaxDefault);
    const dImages = getLayerImages(layerD, localStorage.getItem(middleLayerDMinKey) ?? middleLayerDMinDefault, localStorage.getItem(middleLayerDMaxKey) ?? middleLayerDMaxDefault);
    const eImages = getLayerImages(layerE, localStorage.getItem(middleLayerEMinKey) ?? middleLayerEMinDefault, localStorage.getItem(middleLayerEMaxKey) ?? middleLayerEMaxDefault);
    const fImages = getLayerImages(layerF, localStorage.getItem(middleLayerFMinKey) ?? middleLayerFMinDefault, localStorage.getItem(middleLayerFMaxKey) ?? middleLayerFMaxDefault);
    return [...baseImages, ...aImages, ...bImages, ...cImages, ...dImages, ...eImages, ...fImages];
}

const App = () => {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setImages(createImageArray());
    }, []);

    const handleOnClick = () => {
        setIsLoading(true);
        setImages(createImageArray());
    }

    return (
        <div className="app-container" onClick={handleOnClick}>
            <ImageDisplayer images={images} isLoading={isLoading} setIsLoading={setIsLoading} />
        </div>
    )
}

export default App;