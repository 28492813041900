import React, { useState } from 'react'
import Timer from 'react-compound-timer'

import { Row } from 'react-bootstrap'

const Record = ({ handleClick, startRecording, stopRecording, setAudioObj, setAudioUrl, setAudioBlob }) => {
  const [isRecording, setIsRecording] = useState(false)

  const handleRecordClick = () => {
    setIsRecording(true)
    startRecording()
  }

  const handleStopClick = () => {
    setIsRecording(false)
    stopRecording().then((data) => {
      setAudioObj(data.audioObj)
      setAudioUrl(data.audioUrl)
      setAudioBlob(data.audioBlob)
      handleClick()
    })
  }

  return(
    <>
      <Row>
        <p className='title'>Leave us a message!</p>
        <p className='content ml-auto'>
          Step 1 of 3
        </p>
      </Row>
      <Row>
        <p className='content'>
          Press the record button and begin:
        </p>
      </Row>
      <Row>
        {isRecording ?
          <>
            <button className='stopButton' onClick={handleStopClick}><span className='box' />Stop Recording</button>
            <div className='timerDiv'>
              <Timer
                checkpoints={[
                  {
                    time: 2 * 60 * 1000,
                    callback: handleStopClick,
                  }
                ]}
              >
                <Timer.Minutes />:<Timer.Seconds formatValue={value => value < 10 ? `0${value}` : value} />
              </Timer>
            </div>
          </>
          :
          <>
            <button className='recordButton' onClick={handleRecordClick}><span className='dot' />Start Recording</button>

          </>
        }
      </Row>
      <Row>
        <p className='content'>
          Just press record, take a breath, tell us your name & where you're calling
          from and leave your message.
        </p>
      </Row>
    </>
  )
}

export default Record
