import React, { useState } from 'react';

import { 
    baseLayerMinKey,
    baseLayerMaxKey,
    middleLayerAMinKey,
    middleLayerAMaxKey,
    middleLayerBMinKey,
    middleLayerBMaxKey,
    middleLayerCMinKey,
    middleLayerCMaxKey,
    middleLayerDMinKey,
    middleLayerDMaxKey,
    middleLayerEMinKey,
    middleLayerEMaxKey,
    middleLayerFMinKey,
    middleLayerFMaxKey,
    baseLayerMinDefault,
    baseLayerMaxDefault,
    middleLayerAMinDefault,
    middleLayerAMaxDefault,
    middleLayerBMinDefault,
    middleLayerBMaxDefault,
    middleLayerCMinDefault,
    middleLayerCMaxDefault,
    middleLayerDMinDefault,
    middleLayerDMaxDefault,
    middleLayerEMinDefault,
    middleLayerEMaxDefault,
    middleLayerFMinDefault,
    middleLayerFMaxDefault,
} from '../constants';

const createOptions = () => {
    const items = [];
    for (let i = 0; i <= 10; i++) {
        items.push(<option key={i} value={i}>{i}</option>)
    }
    return items;
}

const App = () => {
    const [baseLayerMin, setBaseLayerMin] = useState(localStorage.getItem(baseLayerMinKey) ?? baseLayerMinDefault);
    const [baseLayerMax, setBaseLayerMax] = useState(localStorage.getItem(baseLayerMaxKey) ?? baseLayerMaxDefault);
    const [middleLayerAMin, setMiddleLayerAMin] = useState(localStorage.getItem(middleLayerAMinKey) ?? middleLayerAMinDefault);
    const [middleLayerAMax, setMiddleLayerAMax] = useState(localStorage.getItem(middleLayerAMaxKey) ?? middleLayerAMaxDefault);
    const [middleLayerBMin, setMiddleLayerBMin] = useState(localStorage.getItem(middleLayerBMinKey) ?? middleLayerBMinDefault);
    const [middleLayerBMax, setMiddleLayerBMax] = useState(localStorage.getItem(middleLayerBMaxKey) ?? middleLayerBMaxDefault);
    const [middleLayerCMin, setMiddleLayerCMin] = useState(localStorage.getItem(middleLayerCMinKey) ?? middleLayerCMinDefault);
    const [middleLayerCMax, setMiddleLayerCMax] = useState(localStorage.getItem(middleLayerCMaxKey) ?? middleLayerCMaxDefault);
    const [middleLayerDMin, setMiddleLayerDMin] = useState(localStorage.getItem(middleLayerDMinKey) ?? middleLayerDMinDefault);
    const [middleLayerDMax, setMiddleLayerDMax] = useState(localStorage.getItem(middleLayerDMaxKey) ?? middleLayerDMaxDefault);
    const [middleLayerEMin, setMiddleLayerEMin] = useState(localStorage.getItem(middleLayerEMinKey) ?? middleLayerEMinDefault);
    const [middleLayerEMax, setMiddleLayerEMax] = useState(localStorage.getItem(middleLayerEMaxKey) ?? middleLayerEMaxDefault);
    const [middleLayerFMin, setMiddleLayerFMin] = useState(localStorage.getItem(middleLayerFMinKey) ?? middleLayerFMinDefault);
    const [middleLayerFMax, setMiddleLayerFMax] = useState(localStorage.getItem(middleLayerFMaxKey) ?? middleLayerFMaxDefault);

    const handleOnChange = (e, key, setFunc) => {
        localStorage.setItem(key, parseInt(e.target.value));
        setFunc(e.target.value);
    }

    const resetToDefault = (key, defaultVal, setFunc) => {
        localStorage.setItem(key, defaultVal);
        setFunc(defaultVal);
    }

    const handleResetClick = () => {
        resetToDefault(baseLayerMinKey, baseLayerMinDefault, setBaseLayerMin);
        resetToDefault(baseLayerMaxKey, baseLayerMaxDefault, setBaseLayerMax);
        resetToDefault(middleLayerAMinKey, middleLayerAMinDefault, setMiddleLayerAMin);
        resetToDefault(middleLayerAMaxKey, middleLayerAMaxDefault, setMiddleLayerAMax);
        resetToDefault(middleLayerBMinKey, middleLayerBMinDefault, setMiddleLayerBMin);
        resetToDefault(middleLayerBMaxKey, middleLayerBMaxDefault, setMiddleLayerBMax);
        resetToDefault(middleLayerCMinKey, middleLayerCMinDefault, setMiddleLayerCMin);
        resetToDefault(middleLayerCMaxKey, middleLayerCMaxDefault, setMiddleLayerCMax);
        resetToDefault(middleLayerDMinKey, middleLayerDMinDefault, setMiddleLayerDMin);
        resetToDefault(middleLayerDMaxKey, middleLayerDMaxDefault, setMiddleLayerDMax);
        resetToDefault(middleLayerEMinKey, middleLayerEMinDefault, setMiddleLayerEMin);
        resetToDefault(middleLayerEMaxKey, middleLayerEMaxDefault, setMiddleLayerEMax);
        resetToDefault(middleLayerFMinKey, middleLayerFMinDefault, setMiddleLayerFMin);
        resetToDefault(middleLayerFMaxKey, middleLayerFMaxDefault, setMiddleLayerFMax);
    }
    
    return (
        <div>
            BaseLayer: min <select value={baseLayerMin} onChange={e => handleOnChange(e, baseLayerMinKey, setBaseLayerMin)}>{createOptions()}</select> max: <select value={baseLayerMax} onChange={e => handleOnChange(e, baseLayerMaxKey, setBaseLayerMax)}>{createOptions()}</select>
            <br />
            MiddleLayerA: min <select value={middleLayerAMin} onChange={e => handleOnChange(e, middleLayerAMinKey, setMiddleLayerAMin)}>{createOptions()}</select> max: <select value={middleLayerAMax} onChange={e => handleOnChange(e, middleLayerAMaxKey, setMiddleLayerAMax)}>{createOptions()}</select>
            <br />
            MiddleLayerB: min <select value={middleLayerBMin} onChange={e => handleOnChange(e, middleLayerBMinKey, setMiddleLayerBMin)}>{createOptions()}</select> max: <select value={middleLayerBMax} onChange={e => handleOnChange(e, middleLayerBMaxKey, setMiddleLayerBMax)}>{createOptions()}</select>
            <br />
            MiddleLayerC: min <select value={middleLayerCMin} onChange={e => handleOnChange(e, middleLayerCMinKey, setMiddleLayerCMin)}>{createOptions()}</select> max: <select value={middleLayerCMax} onChange={e => handleOnChange(e, middleLayerCMaxKey, setMiddleLayerCMax)}>{createOptions()}</select>
            <br />
            MiddleLayerD: min <select value={middleLayerDMin} onChange={e => handleOnChange(e, middleLayerDMinKey, setMiddleLayerDMin)}>{createOptions()}</select> max: <select value={middleLayerDMax} onChange={e => handleOnChange(e, middleLayerDMaxKey, setMiddleLayerDMax)}>{createOptions()}</select>
            <br />
            MiddleLayerE: min <select value={middleLayerEMin} onChange={e => handleOnChange(e, middleLayerEMinKey, setMiddleLayerEMin)}>{createOptions()}</select> max: <select value={middleLayerEMax} onChange={e => handleOnChange(e, middleLayerEMaxKey, setMiddleLayerEMax)}>{createOptions()}</select>
            <br />
            MiddleLayerF: min <select value={middleLayerFMin} onChange={e => handleOnChange(e, middleLayerFMinKey, setMiddleLayerFMin)}>{createOptions()}</select> max: <select value={middleLayerFMax} onChange={e => handleOnChange(e, middleLayerFMaxKey, setMiddleLayerFMax)}>{createOptions()}</select>
            <br />
            <button onClick={handleResetClick}>Reset to default</button>
        </div>
    )
}

export default App;