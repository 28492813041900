import React, { useEffect } from 'react';

const ImageDisplayer = ({ images, isLoading, setIsLoading }) => {
    useEffect(() => {
        if (document.images.length !== 0) {
            Promise.all(
                Array.from(document.images)
                    .filter(img => !img.complete)
                    .map(img => new Promise(
                        resolve => { img.onload = img.onerror = resolve; }
                    ))).then(() => {
                        setIsLoading(false);
                    });
        }
    }, [images]);

    return (
        <>
            <div className={isLoading ? '' : 'hidden'}>Loading...</div> 
            <>{images.map((src, i) => <img className={isLoading ? 'hidden' : ''} key={i} src={src} />)}</>
        </>
    )
}

export default ImageDisplayer;