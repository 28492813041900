export const baseLayerMinKey = 'baseLayerMin';
export const baseLayerMaxKey = 'baseLayerMax';
export const middleLayerAMinKey = 'middleLayerAMinKey';
export const middleLayerAMaxKey = 'middleLayerAMaxKey';
export const middleLayerBMinKey = 'middleLayerBMinKey';
export const middleLayerBMaxKey = 'middleLayerBMaxKey';
export const middleLayerCMinKey = 'middleLayerCMinKey';
export const middleLayerCMaxKey = 'middleLayerCMaxKey';
export const middleLayerDMinKey = 'middleLayerDMinKey';
export const middleLayerDMaxKey = 'middleLayerDMaxKey';
export const middleLayerEMinKey = 'middleLayerEMinKey';
export const middleLayerEMaxKey = 'middleLayerEMaxKey';
export const middleLayerFMinKey = 'middleLayerFMinKey';
export const middleLayerFMaxKey = 'middleLayerFMaxKey';

export const baseLayerMinDefault = 1;
export const baseLayerMaxDefault = 1;
export const middleLayerAMinDefault = 0;
export const middleLayerAMaxDefault = 2;
export const middleLayerBMinDefault = 1;
export const middleLayerBMaxDefault = 4;
export const middleLayerCMinDefault = 0;
export const middleLayerCMaxDefault = 1;
export const middleLayerDMinDefault = 1;
export const middleLayerDMaxDefault = 1;
export const middleLayerEMinDefault = 1;
export const middleLayerEMaxDefault = 2;
export const middleLayerFMinDefault = 1;
export const middleLayerFMaxDefault = 2;